import { create } from "zustand";
import { fetchData } from "../fetchData";
import { subscribeWithSelector } from "zustand/middleware";
import jwt_decode from "jwt-decode";
import TagManager from "react-gtm-module";

const localCompany = localStorage.getItem(
  `${process.env.REACT_APP_KEYNAME}company`,
);

const parseJwt = (token: any) => {
  try {
    return jwt_decode(token);
  } catch (error) {
    console.log(error);
    return null;
  }
};

type User = {
  name: string;
  nickname: string;
  client: Array<any>;
};

type Company = {
  state: boolean;
  payroll: boolean;
  name?: string;
  reference?: number;
  _id?: string;
};

type State = {
  user: undefined | User;
  company: Company;
};

type Actions = {
  login: (login: string, password: string) => Promise<boolean>;
  logout: () => void;
  tokenVerify: (update?: boolean) => void;
  switchOrganization: (data: Company) => void;
};

const initialState: State = {
  user: undefined,
  company: localCompany
    ? JSON.parse(localCompany)
    : {
        state: true,
        payroll: false,
      },
};

const useAuthStore = create<State & Actions>((set, get) => ({
  ...initialState,

  login: async (username: string, password: string) => {
    try {
      const result = await fetchData("/auth", {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (result) {
        const decodedJWT: any = parseJwt(result.access_token);

        if (decodedJWT.is_admin) return false;

        localStorage.setItem(
          `${process.env.REACT_APP_KEYNAME}token`,
          result.access_token,
        );

        TagManager.dataLayer({
          dataLayer: {
            event: "signin",
            organization: decodedJWT.client[0],
            user: decodedJWT,
          },
        });

        set(() => ({ user: decodedJWT }));

        if (!localCompany) {
          set(() => ({
            company: {
              state: false,
              ...decodedJWT.client[0],
            },
          }));
        }

        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  logout: () => {
    localStorage.removeItem(`${process.env.REACT_APP_KEYNAME}token`);
    localStorage.clear();
    set(() => ({ user: undefined }));
  },

  tokenVerify: (update = false) => {
    try {
      const decodedJWT: any = parseJwt(
        localStorage.getItem(`${process.env.REACT_APP_KEYNAME}token`),
      );

      if (decodedJWT.exp * 1000 < Date.now()) {
        get().logout();
        return false;
      }

      if (update) {
        set(() => ({ user: decodedJWT }));

        if (!localCompany) {
          set(() => ({
            company: {
              state: false,
              ...decodedJWT.client[0],
            },
          }));
        }
      }
    } catch (error) {
      get().logout();
    }
  },

  switchOrganization: (data: Company) =>
    set(() => {
      localStorage.setItem(
        `${process.env.REACT_APP_KEYNAME}company`,
        JSON.stringify({ ...data, state: false }),
      );

      return {
        company: { ...data, state: false },
      };
    }),
}));

export default useAuthStore;
